.wrapper {
  width: 100%;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
}
.billingWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  width: max-content;
  padding: 23px;
}
// .rutWrapper {
//   color: '#212121';
//   font-size: 22px;
//   font-weight: 900;
//   // text-align: center;
// }
.card{
  box-shadow: none;
}
.rutWrapper {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--bx-color-black);
  @media (min-width: 1200px) {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 5px;
  }
}
.rutButtons {
  display: flex;
  justify-content: space-between;
}
.title {
  font-weight: 900;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}
.box {
  display: flex;
  justify-content: center;
}
.boxBilling {
  display: flex;
  justify-content: space-evenly;
}
.imageBox {
  width: 137px;
  margin-bottom: 16px;
  height: 105px;
}
.imageBilling {
  height: 101px;
  left: 512px;
}
.img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.text {
  font-size: 14px;
  height: 50px;
  margin-bottom: 35px;
  text-align: center;
  @media (min-width: 1200px) {
    text-align: left;
  }
}
.textBilling {
  font-size: 14px;
  text-align: center;
  margin: 16px 0 24px 0;
}
.required {
  color: var(--bx-color-orange);
}
.error {
  font-weight: 800;
  font-size: 12px;
  color: var(--bx-color-red-alert);
}
.subtitle{
  font-size: 12px;
}
.modal{
  margin-right: 10px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 30px;
  border-radius: 15px;
  width: 351px;
  height: 186px;
  background-color: var(--bx-color-white);
  box-shadow: 0px 6px 14px rgba(24, 39, 75, 0.12), 0px 10px 32px rgba(24, 39, 75, 0.1);
}

// .t1 {
//   width: 200px;
//   height: 20px;
//   padding-right: 50px;
// }

// .t2 {
//   margin-left: -50px;
//   height: 25px;
//   width: 50px;
//   background: blue;
//   color: white;
//   border: 0;
//   -webkit-appearance: none;
// }

.inputC {
  // border-style: none;
  // appearance: none;
  // background-color: transparent;
  // width: 100%;
  // text-align: center;
  // padding-right: 0px;
  // font-size: 1.125rem;
  // line-height: 1.75rem;
  // font-weight: 600;
  // padding-left: 0.25rem;
  &:focus {
    border-style: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: red;
  }
}


.custom-search {
  position: relative;
  width: 300px;
}
.custom-search-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px; 
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  background: #FFFFFF;
  height: 48px;
  border-radius: 15px;
  border-color: #FF7A00;
}
.custom-search-botton {
  position: absolute;
  right: 1px; 
  // top: 3px;
  // bottom: 3px;
  border: 0;
  background: #FF7A00;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  width: 64px;
  height: 48px;
  border-radius: 0px 10px 10px 0px;
  
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.inside-btn {
  display:flex;
  justify-content: center;
}