.namesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px 0 8px 0;
}
.username {
  color: var(--bx-color-grey-one);
  font-size: 14px;
  font-weight: bolder;
}
.pymename {
  color: var(--bx-color-blue-rock);
  font-size: 16px;
  font-weight: bolder;
}
.hr {
  background-color: var(--bx-color-grey-one);
  margin: 4px;
}
