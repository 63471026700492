.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader {
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
}
.loaderDots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loaderDots div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--bx-color-blue);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loaderDots div:nth-child(1) {
  left: 8px;
  animation: dotsloader1 0.6s infinite;
}
.loaderDots div:nth-child(2) {
  left: 8px;
  animation: dotsloader2 0.6s infinite;
}
.loaderDots div:nth-child(3) {
  left: 32px;
  animation: dotsloader2 0.6s infinite;
}
.loaderDots div:nth-child(4) {
  left: 56px;
  animation: dotsloader3 0.6s infinite;
}
@keyframes dotsloader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes dotsloader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes dotsloader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
