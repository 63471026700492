.breadcrumb-ol {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
.breadcrumb-item {
  display: flex;
  flex-direction: row;
  & + & {
    padding-left: 0.5rem;
  }
  & + &::before {
    float: left;
    padding-right: 0.5rem;
    color: var(--bx-color-blue);
    content: '>';
  }
}
.breadcrumb-item-is-active {
  color: var(--bx-color-orange);
}
.breadcrumb-item-link {
  text-decoration: none;
  transition: color 0.2s ease;
  color: white;
  @media (min-width: 768px) {
    color: white;
  }
  @media (min-width: 1200px) {
    color: var(--bx-color-blue);
  }
}
