.stepper {
  display: flex;
  align-items: start;
  flex-direction: column;
  position: relative;
  height: 300px;
  width: 80px;
  // @media (min-width: 768px) {
  //   padding: 0 80px;
  //   margin: 0 auto;
  // }
  // @media (min-width: 1200px) {
  //   padding: 0 70px;
  // }
}

.stepper-lines {
  position: absolute;
  display: flex;
  width: 32%;
  height: 92%;
  margin-top: 5.3%;
  // margin-left: 1.5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // top: 20px;
  @media (min-width: 768px) {
    // padding: 0 90px;
  }
  @media (min-width: 1200px) {
    // padding: 0 80px;
  }
}
.step-line {
  height: 100%;
  width: 2px;
  // background-color: var(--bx-color-grey-time);
  background-color: var(--bx-color-grey-time);
}
.step-line-is-active {
  // background-color: var(--bx-color-lblue);
  background-color: #3363ff;
}
.stepper-steps {
  flex: 1;
  // width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}
.step {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70px;
  @media (min-width: 768px) {
    max-width: 100%;
  }
}
.step-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  // align-items: start;
  align-items: center;
}
.step-wrapper-number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--bx-color-grey-time);
  margin-bottom: 5px;
  width: 37px;
  height: 37px;
  @media (min-width: 768px) {
    width: 28px;
    height: 28px;
  }
}
.step-wrapper-number-is-active {
  // background: var(--bx-color-lblue);3363FF
  background: #3363ff;
}
.step-number {
  color: var(--bx-color-grey-time);
  font-family: var(--bx-font-primary);
  font-weight: 900;
  line-height: 1;
  font-size: 15px;
}
.step-number-is-active {
  color: var(--bx-bg);
}
.step-wrapper-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5% 0 0 3%;
}
.step-text {
  text-align: center;
  font-weight: 900;
  color: var(--bx--fg);
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}
.step-title {
  color: #828282;
  font-weight: 700;
  font-size: 12px;
}
.step-text-is-active {
  color: var(--bx--fg);
}
