.precautionBox {
  background: var(--bx-color-grey-on);
  border: 0.6px solid var(--bx-color-blue-clear);
  border-radius: 10px;
  padding: 26px 16px;
  display: flex;
  align-items: center;
}
.boxIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.text {
  color: var(--bx-color-blue);
  margin: 0;
  font-size: 12px;
}
.secondaryText {
  font-size: 10px;
  font-style: italic;
  margin: 0;
  margin-top: 6px;
  color: var(--bx-color-grey-play);
}
