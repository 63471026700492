.InputTag {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bx-color-black);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--bx-color-lblue-our);
  border-radius: 1rem;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Tag {
  margin: 3px 0;
  margin-right: 5px;
  @media (min-width: 1200px) {
    margin: 10px 0;
    margin-right: 5px;
  }
  &:last-of-type {
    margin-right: 0px;
  }
}
.TagBox {
  display: flex;
  align-items: center;
  border-radius: 15px;
  background: var(--bx-color-blue-clear);
  padding: 4px 8px;
}
.TagText {
  font-size: 10px;
  margin-right: 10px;
}
.TagButton {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  height: 8px;
  width: 8px;
}
.TagButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.InputBox {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: auto;
  flex-grow: 1;
}
.Input {
  border: none;
  appearance: none;
  background: transparent;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bx-color-black);
  font-family: var(--bx-font-secondary);
  min-width: 1px;
  font-size: 14px;
  display: flex;
  height: 100%;
  width: 100%;
  @media (min-width: 1200px) {
    font-size: 1rem;
  }
  &:focus {
    outline: none;
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: var(--bx-form-disabled);
  }
}
