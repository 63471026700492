.footer {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
}

.text {
  display: none;
  color: var(--bx-color-lblue-give);
  @media (min-width: 768px) {
    display: flex;
    font-size: 14px;
  }
}

.mobileText {
  font-size: 14px;
  color: var(--bx-color-lblue-give);
  text-align: center;
  @media (min-width: 768px) {
    display: none;
  }
}
